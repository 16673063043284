import React, { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom';
import { getRooms } from '../resources';
import KitchenGrid from './kitchen/KitchenGrid';
import KitchenHeader from './kitchen/KitchenHeader';
import { getDateDetailsByDate } from '../helpers/KitchenHelper';

const CocinaCalendar = () => {

    const TIMER = 60000 * 10;

    const screenPart = document.getElementById('cocinaCalendar').getAttribute('data-screen-part');

    const [rooms,setRooms] = useState({
        loading: true,
        data: []
    });
    const [params, setParams] = useState({
        page: '',
        tab: 'Desayuno',
        dateSelected: new Date(),
    });

    const updateParams = (key, value) => {
        setParams({
            ...params,
            [key]: value
        });
    }

    const dateInfo = useMemo(() => getDateDetailsByDate(params.dateSelected),[params.dateSelected]);

    const getData = async() => {
        setRooms({
            ...rooms,
            loading: true
        });
        updateParams('loading', true);
        const response = await getRooms(dateInfo.currentDate, screenPart);
        if(response){
            setRooms({
                loading: false,
                data: response
            });
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getData();
        }, TIMER);
        getData();
        return () => clearInterval(interval);
    },[]);

    useEffect(() => {
        getData();
    },[params.dateSelected]);


    useEffect(() => {
        const hour = dateInfo.hour;
        //console.log(hour);
        let tab = '';
        if ( hour < 12 ) {
            tab = 'Desayuno';
        }else if (hour >= 12 && hour < 17) {
            tab = 'Comida';
        } else if (hour >= 17 && hour < 24) {
            tab = 'Cena';
        }
        updateParams('tab', tab);
    },[dateInfo.hour]);


  return (
    <>
        <KitchenHeader params={params} rooms={rooms} updateParams={updateParams} dateInfo={dateInfo} />
        <KitchenGrid params={params} rooms={rooms} />
    </>
  )
}

if (document.getElementById('cocinaCalendar')) {
    ReactDOM.render(<CocinaCalendar />, document.getElementById('cocinaCalendar'));
}
