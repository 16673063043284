import React from 'react'
import { useMemo } from 'react';
import Tabs from './Tabs';

const KitchenHeader = ({params, rooms, dateInfo, updateParams }) => {

    const {tab, page} = params;

    const pages = useMemo(() => {
        if ( rooms.data.length > 0 ) {
            const roomsLength = rooms.data.length;
            const pagesLength = Math.ceil(roomsLength / 9);
            return pagesLength;
        }
        return 0;
    },[rooms.data]);

    const changePage = (e, page) => {
        e.preventDefault();
        updateParams('page', page);
    }

    const updateDate = (e) => {
        e.preventDefault();
        updateParams('dateSelected', new Date(e.target.value+'T08:00:00'));
    }

    const numDiabeticsIndicator = document.getElementById('cocinaCalendar').getAttribute('data-numDiabetics');
    const numMealsIndicator = document.getElementById('cocinaCalendar').getAttribute('data-numMeal');

    return (
        <>
        <div className="mntl-dash_history-hdr dFlex align-items-center justify-content-between head-w-m fixed bg-green">
            <div className='col-12'>
                <div className="row">
                    <div className="col-3 d-flex align-center justify-space-around">
                        <h1 className="title">
                            {tab}
                        </h1>
                        <div className='wrap-indicators'>
                            <small className="mealsIndicator">N/ Comidas <strong>( {numMealsIndicator} )</strong></small>
                            <small className="diabetesIndicator">P/ Diabetes <strong>( {numDiabeticsIndicator} )</strong></small>
                        </div>
                        <div className='pages-calendar d-none'>
                            <a href="#" className={`${(!page) ? 'active' : ''}`} onClick={(e) => changePage(e, false)}>Todos</a> |
                            {[...Array(pages).keys()].map((p, index) => {
                                return (
                                    <span key={`page-${index}`}>
                                        <a href="#" className={`${(page == p+1) ? 'active' : ''}`} onClick={(e) => changePage(e, p+1)}>
                                            {p+1}
                                        </a> 
                                        {p < 1 &&
                                            <span>|</span>
                                        }
                                    </span>
                                )
                                })}
                        </div>
                    </div>
                    <div className="col-4">
                        <Tabs tab={tab} updateParams={updateParams} />
                    </div>
                    <div className="col-2">
                        <div className='mt-4 dFlex justify-content-between aligned-center' style={{maxWidth: 205}}>
                            <a href='/admin/cocina?screen=1'>Pantalla 1</a>
                            <a href='/admin/cocina?screen=2'>Pantalla 2</a>
                        </div>
                    </div>
                    <div className="col-3 wrap-right">
                        <span className="room-number">Fecha</span>
                        <span className="residence-date" style={{fontSize: 20, color: 'white'}}>
                            <input type='date' style={{width: 46, display: 'inline-block', marginRight: 5}} value={dateInfo.currentDate} className='form-control' onChange={updateDate} />
                            {dateInfo.todayDate}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default KitchenHeader