import React from 'react'
import RoomItem from './RoomItem'
import Slider from "react-slick"

const KitchenGrid = ({rooms, params}) => {

    const array = [...rooms.data];
    const middleIndex = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, middleIndex);
    const secondHalf = array.slice(middleIndex);
    //const thirdHalf = array.slice(middleIndex * 2);

    /*
    console.log(rooms);
    //console.log(firstHalf);
    //console.log(secondHalf);
    //console.log(thirdHalf);
    */
    var numSlidesToShowS1 = 3;
    var numSlidesToShowS2 = 3;
    if(firstHalf.length == 1) {
        numSlidesToShowS1 = 1;
    }
    if(secondHalf.length == 1) {
        numSlidesToShowS2 = 1;
    }

    if(firstHalf.length == 2) {
        numSlidesToShowS1 = 2;
    }
    if(secondHalf.length == 2) {
        numSlidesToShowS2 = 2;
    }
    
    console.log(firstHalf.length, secondHalf.length);
    console.log(numSlidesToShowS1, numSlidesToShowS2);

    var sliderSettingsSlider1 = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        speed: 200,
        autoplaySpeed: 15000,
        slidesToShow: numSlidesToShowS1,
        slidesToScroll: numSlidesToShowS1,
        //fade: true
      };
    
      var sliderSettingsSlider2 = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        speed: 200,
        autoplaySpeed: 15000,
        slidesToShow: numSlidesToShowS2,
        slidesToScroll: numSlidesToShowS2,
        //fade: true
      };

    const {tab, page} = params;

    return (
        <div className="dash-home calendar col-12 dFlex margin-bar">
            <div className='sliderKitchenRooms'>
                <Slider {...sliderSettingsSlider1}>
                    
                        {rooms.loading &&
                            <div className='col-12'>
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                </div>
                            </div>
                        }
                        {firstHalf && firstHalf.map((r, index) => {
                            if ( !page || (index >= ((page -1) * 9) && index < (page * 9))) {
                                return (
                                    <RoomItem key={`room-${r.id}`} room={r} tab={tab} />
                                )
                            }
                        })}
                </Slider>
            </div>
            
            <div className='sliderKitchenRooms'>
                <Slider {...sliderSettingsSlider2}>
                    
                        {rooms.loading &&
                            <div className='col-12'>
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                </div>
                            </div>
                        }
                        {secondHalf && secondHalf.map((r, index) => {
                            if ( !page || (index >= ((page -1) * 9) && index < (page * 9))) {
                                return (
                                    <RoomItem key={`room-${r.id}`} room={r} tab={tab} />
                                )
                            }
                        })}
                </Slider>
            </div>
        </div>

        /*
        <div className="dash-home calendar col-12 dFlex margin-bar">
            {rooms.loading &&
                <div className='col-12 mb-4'>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                    </div>
                </div>
            }
            {rooms.data && rooms.data.map((r, index) => {
                if ( !page || (index >= ((page -1) * 9) && index < (page * 9))) {
                    return (
                        <RoomItem key={`room-${r.id}`} room={r} tab={tab} />
                    )
                }
            })}

        </div>
        */
    )
}

export default KitchenGrid