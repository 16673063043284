import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/es'; // Importa el archivo de idioma español
import { getFoods, saveFood } from '../resources';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';
import SingleFood from './SingleFood';

moment.locale('es'); // Establece el idioma a español

const PatientFood = () => {
    const MySwal = withReactContent(Swal);
    const [data, setData] = useState([]);
    const [currentDay, setCurrentDay] = useState({
        day: '',
        title: '',
        value: '',
        breakfast: null,
        breakfast_comment: null,
        lunch: null,
        lunch_comment: null,
        dinner: null,
        dinner_comment: null,
        reference_food_id : null,
        reference_id : null,
    });

    useEffect(() => {
        const gFood = async() => {
            const response = await getFoods();
            if (response.success) {
                // Convierte los días a nombres en español y agrega el número del día
                const daysWithFormattedTitles = response.days.map(day => ({
                    ...day,
                    title: moment(day.value, 'YYYY-MM-DD').format('dddd D'), // Ajusta el formato según tu fecha
                }));
                setData({ ...response, days: daysWithFormattedTitles });
            }
        };
        gFood();
    }, []);

    useEffect(() => {
        if ( data.days ) {
            if(currentDay.value == '') {
                setCurrentDay(data.days[0]);
            } else {
                let day = data.days.find(d => d.day === currentDay.day);
                setCurrentDay(day);
            }
        }
    }, [data]);

    // El resto del código sigue igual...
    const getTitle = (cat) => {
        let title = '';
        switch(cat) {
            case 1:
                title = 'Desayuno';
                break;
            case 2:
                title = 'Comida';
                break;
            case 3:
                title = 'Cena';
                break;
        }
        return title;
    };

    const showModal = (e, cat) => {
        e.preventDefault();
        let currentMeal = '';
        const title = getTitle(cat);
        const categories = data.foods.filter((f) => f.category === cat);
        const options = categories.map(c => ({ label: c.title, value: c.id }));
        let currentPlaceToEat = 'comedor';
        const optionsWhereToEat = [
            { value: 'comedor', label: 'Comedor' },
            { value: 'habitación', label: 'Habitación' }
        ];

        MySwal.fire({
            width: 600,
            padding: "0px",
            customClass: {
                popup: 'no-padding-sweet add-item',
            },
            confirmButtonText: 'Editar',
            showCloseButton: true,
            preConfirm: () => {
                if (!currentMeal) {
                  alert('Selecciona una opción');
                  return false; // Evita confirmar si no se ha seleccionado nada
                }
            },
            html: (
                <>
                    <div className="sweetalert-header">
                        <div className="terapia">Seleccionar {title}</div>
                    </div>
                    <div style={{margin: 15}}>
                        <strong>{title}</strong>
                        <Select
                            menuPlacement='auto'
                            menuPosition="fixed"
                            onChange={(opt) => {currentMeal = opt.value}}
                            options={options}
                        />
                    </div>
                    <div style={{margin: 15}}>
                        <strong>¿Dónde deseas tomar tus alimentos?</strong>
                        <Select
                            menuPlacement='auto'
                            menuPosition="fixed"
                            onChange={(opt) => {currentPlaceToEat = opt.value}}
                            options={optionsWhereToEat}
                        />
                    </div>
                </>
            )
        }).then(async (result) => {
            if (result.isConfirmed) {
                await saveFood(currentMeal, currentPlaceToEat, cat, currentDay.day, currentDay.residence_id, currentDay.value);
                const response = await getFoods();
                if (response.success) {
                    setData(response);
                }
            }
        });
    };

    const changeDay = (e, day) => {
        e.preventDefault();
        setCurrentDay(day);
    };

    return (
        <>
            <div className="text-center col-12">
                <div className="nav-terapias">
                    <ul>
                        {data.days && data.days.map(d => (
                            <li key={`day-${d.day}`} className={`${(d.day === currentDay.day) ? 'active' : ''}`}>
                                <a href="#" onClick={(e) => changeDay(e,d)}>{d.title}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="col-12 bg-gray" style={{width: '100%'}}>
                <div className='row'>
                    <div className='col-10 offset-1'>
                        <div className='row'>
                            <SingleFood title="Desayuno" food={currentDay.breakfast} showModal={showModal} cat={1} doctorComment={currentDay.breakfast_comment} />
                            <SingleFood title="Comida" food={currentDay.lunch} showModal={showModal} cat={2} doctorComment={currentDay.lunch_comment} />
                            <SingleFood title="Cena" food={currentDay.dinner} showModal={showModal} cat={3} doctorComment={currentDay.dinner_comment} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientFood;

if (document.getElementById('patient-foods')) {
    ReactDOM.render(<PatientFood />, document.getElementById('patient-foods'));
}
